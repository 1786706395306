<template>
	<div>
		<searchCard>
			<div class="flex">
				<div class="w-1/3">
					<h6>딜 종료일</h6>
					<div class="flex">
						<b-form-radio class="mr-6" v-model="opt.period" name="searchPeriod" value=""
							>전체기간
						</b-form-radio>
						<b-form-radio v-model="opt.period" name="searchPeriod" value="period" />
						<div class="flex -mt-1.5">
							<datePicker class="w-3/7" model="opt.startDate" />
							<span class="p-2 lh-6">~</span>
							<datePicker class="w-3/7" model="opt.endDate" />
						</div>
					</div>
				</div>

				<div class="w-1/5 verticalCenter">
					<h6 class="w-1/1">&nbsp;</h6>
					<b-form-checkbox class="" v-model="opt.isInvalidAccountInfo" color="primary"
						>입금정보 미입력만 조회
					</b-form-checkbox>
				</div>

				<div class="w-1/2">
					<h6 class="w-1/1">핫티스트 검색</h6>
					<div class="flex">
						<vSelect
							class="w-1/4"
							v-model="opt.searchKeyName"
							:clearable="false"
							:options="searchKeyOpts"
						/>
						<b-form-input
							class="w-2/5 ml-4 mr-2"
							v-model.trim="opt.searchKeyword"
							placeholder="검색어"
							@keyup.enter="search"
						/>
						<b-button class="px-4 -top-0.5" @click="search">정산 대상 조회</b-button>
					</div>
				</div>
			</div>
		</searchCard>
		<b-card>
			<div class="flex-between mb-4">
				<div>
					<b-button @click="excelDownload('accounting')" variant="info">정산 내역 다운로드</b-button>
					<b-button class="ml-4" @click="excelDownload('hottist')" variant="warning"
						>핫티스트별 정산내역 다운로드
					</b-button>
				</div>
				<b-button class="" @click="excelDownload('list')">정산 대상 다운로드</b-button>
			</div>
			<tb :inf="inf" :res="res" />
		</b-card>
		<accountingPopup />
	</div>
</template>

<script>
/*
 오늘 날짜가 16일 미만일 경우
 디폴트 시작일 : 전월 16일
 디폴트 종료일 : 전월 말일
 오늘 날짜가 16일 이상일 경우
 디폴트 시작일 : 당월 1일
 디폴트 종료일 : 당월 15일
 */
import accountingPopup from 'pages/accountingPopup'

const now = new Date(),
	year = now.getFullYear()
let month = now.getMonth(),
	startDate,
	endDate

if (now.getDate() < 16) {
	month--
	startDate = 16
	endDate = new Date(now.getFullYear(), now.getMonth(), 0).getDate()
} else {
	startDate = 1
	endDate = 15
}

const searchKeyOpts = [
		{ label: '핫트 아이디', value: 'hottId' },
		{ label: '인스타 아이디', value: 'currentInstaId' },
		{ label: '이름', value: 'name' },
		{ label: '휴대폰번호', value: 'phone' },
	],
	defOpt = {
		period: 'period',
		startDate: new Date(year, month, startDate),
		endDate: new Date(year, month, endDate),
		isInvalidAccountInfo: false,
		searchKeyName: searchKeyOpts[0],
		searchKeyword: '',
	}
//검색조건 기본값. 검색 조건 초기화를 위해 사용

export default {
	components: { accountingPopup },
	data() {
		return {
			searchKeyOpts,
			opt: { ...defOpt },
			defOpt,
			res: {},
			lastOpt: {},

			inf: [
				{ title: 'No.', model: 'idx', size: 2 },
				{ title: '핫트아이디', key: 'hottId', size: 6 },
				{ title: '이름', key: 'name', size: 6 },
				{ title: '인스타 아이디', key: 'instagramId', size: 8 },
				{ title: '휴대폰번호', key: 'phoneNumber', size: 8 },
				{ title: '주민등록번호(사업자등록번호)', key: 'privateNumber', size: 8 },
				{ title: '입금자명', key: 'accountHolder', size: 6 },
				{ title: '은행', key: 'bankName', size: 8 },
				{ title: '계좌번호', key: 'accountNumber', size: 12 },
			],
		}
	},
	methods: {
		search(operation = 'LIKE') {
			//검색 시작
			if (typeof operation != 'string') operation = 'LIKE'
			const opt = this.opt,
				startDate = getDateValue(opt.startDate),
				endDate = getDateValue(opt.endDate, false),
				isInvalidAccountInfo = opt.isInvalidAccountInfo,
				searchKey = getDropdownValue(opt.searchKeyName),
				searchVal = opt.searchKeyword

			let data = {
					startDate,
					endDate,
					isInvalidAccountInfo,
					paging: getPaging(),
					adminSeq: layout.user.seqNo,
					isWholePeriod: opt.period != 'period',
				},
				error = []

			//유효성 검사
			if (startDate > endDate) error.push('딜 종료일의 시작일이 종료일보다 큽니다.')

			if (searchVal) data.searchKey = getSearchKey(searchKey, searchVal)

			if (error.length) {
				//유효성 검사에서 오류가 있을 경우 알럿
				alert.w(error)
				return false
			} else {
				//오류가 없으면 검색 조건 설정하고 1페이지로 설정
				this.lastOpt = data
				//this.p.resultTable.selectedItem = {}
				return this.changePage(1)
			}
		},
		changePage(page = 1) {
			//마지막 검색 조건을 기준으로 페이지 변경
			this.lastOpt.paging.pageNo = page - 1
			return postApi('/settlementSelect/hottistsList', this.lastOpt).then(res => {
				//인덱스
				const baseIdx = res.paging.totalCnt - res.paging.currentPage * 10
				res.list = res.list.map((v, i) => {
					v.idx = baseIdx - i
					//accountInfo 압축해제
					Object.keys(v.accountInfo).map(k => {
						v[k] = v.accountInfo[k]
					})
					delete v.accountInfo
					return v
				})
				this.res = res
				return res
			})
		},
		excelDownload(kind, clipboardData) {
			if (this.$acl.get[0] != 'ROLE_ACCOUNTING') {
				alert.w('다운로드 권한이 없습니다.')
				return
			}

			let baseData, url, position
			if (kind == 'accounting') {
				if (!this.opt.period) {
					alert.w('전체기간 정산내역 다운로드는 불가합니다.')
					return
				}
				url = '/settlementSelect/downloadHottistsSettlementList'
				position = '핫티스트 정산 내역 조회'
				baseData = {
					startDate: getDateValue(this.opt.startDate),
					endDate: getDateValue(this.opt.endDate),
					isWholePeriod: false,
				}
				if (clipboardData) {
					position = '핫티스트별 정산내역 다운로드'
					baseData.hottIds = clipboardData.data
				} else if (this.lastOpt.searchKey) baseData.searchKey = this.lastOpt.searchKey
			} else if (kind == 'hottist') {
				this.accountingPopup.open()
				return
			} else {
				if (!this.lastOpt.startDate) {
					alert.w('정산 대상 조회를 먼저 진행해주세요')
					return
				}
				url = '/settlementSelect/downloadHottistsList'
				position = '핫티스트 정산 대상 조회'
				baseData = { ...this.lastOpt }
			}
			const ex = new excel(position)
			if (baseData.isWholePeriod) ex.key('기간 선택', '전체')
			else ex.date(baseData.startDate, baseData.endDate)

			if (baseData.isInvalidAccountInfo) ex.key('입금정보', '미입력만')
			baseData.adminSeq = layout.user.seqNo

			ex.search(baseData.searchKey, searchKeyOpts)

			postApi(url, { ...baseData, excelInfo: ex.get() }).then(() => {
				alert.excel()
			})
		},
	},
	created() {
		//this.search()
	},
}
</script>
