<template>
	<basePopup :okFunc="download" okText="다운로드" title="핫티스트별 정산내역 다운로드">
		<h6>핫티스트 아이디 입력</h6>
		<clipboard :pr="ths" />
	</basePopup>
</template>

<script>
import basePopup from 'comp/local/basePopup'
import clipboard from 'comp/local/clipboard'

export default {
	props: { cName: { default: 'accountingPopup' } },
	components: { basePopup, clipboard },
	data() {
		return {
			isShow: false,

			input: {
				file: null,
			},
		}
	},
	methods: {
		open(item) {
			this.isShow = true

			setTimeout(() => this.clipboard.clear())
		},
		download() {
			if (this.clipboard.e) alert.w(this.clipboard.e)
			//엑셀 클립보드로 붙여넣기
			else {
				this.clipboard.getPromise().then(data => {
					this.p.excelDownload('accounting', data)
				})
			}
		},
	},
}
</script>
